import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Burnout Test - Frazzled</title>
                <meta name="description" content="Discover where happiness really is in your career. Take the test and fix your burnout." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col mt-5 mb-5">
                                    <div className="container">
                                        <div className="row justify-content-center mt-5 mb-5">
                                            <div className="row m-5 w-100">
                                                <div className="col-lg-12 col-12 d-flex flex-column justify-content-center text-center">
                                                    <h1 className="d-none d-md-block" style={{ fontSize: '4.5em' }}>Feeling burn out at work?</h1>
                                                    <h1 className="d-block d-md-none" style={{ fontSize: '3em', marginBottom: '2rem' }}>Feeling burn out at work?</h1>
                                                    <h4 className="mb-0">Discover your burnout level, compare with peers, and explore <br></br> where you might thrive—whether it’s a new company or a different role.</h4>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <Link to="/test" className="btn btn-lg text-white fw-bold mt-5 shadow" style={{ backgroundColor: '#EA526F', color: '#FFFFFF' }}>Take the Test</Link>
                                                        <p className="text-center mt-4 text-muted"><i className="bi bi-clock me-2"></i>4 minutes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="col-12 mb-5 mt-5">
                                <div className="bg-white p-4 shadow-lg rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                    <div className="row g-4"> {/* Added `g-4` for consistent gap between columns */}
                                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center px-4 px-md-5"> {/* Responsive padding */}
                                            <h1 className="mt-3 mb-4">What is Frazzled?</h1>
                                            <p className="fs-5">Frazzled is a reviews site with a difference. Instead of opinions, we use numbers to give you the best possible insights on how you and your peers feel, companies, and much more.</p>
                                            <p className="fs-5 mt-3"><i className="bi bi-check me-2"></i>Burnout Test</p>
                                            <p className="fs-5"><i className="bi bi-check me-2"></i>Analysis of Results</p>
                                            <p className="fs-5"><i className="bi bi-check me-2"></i>Comparison to your Peers</p>
                                            <p className="fs-5"><i className="bi bi-check me-2"></i>Data Driven Career Decisions</p>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex justify-content-center">
                                            <div className="w-100 w-md-75"> {/* Ensured the card scales responsively */}
                                                <Link to={`/news/3`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    <div className="card h-100">
                                                        <img src="/images/glassdoor.jpg" className="card-img-top" alt="Frazzled vs. The Rest: Why This Review Site Is Different—and Better" style={{ objectFit: 'cover' }} /> {/* Ensured image fits properly */}
                                                        <div className="card-body">
                                                            <h5 className="card-title">Frazzled vs. The Rest: Why This Review Site Is Different—and Better</h5>
                                                            <p className="card-text">"What sets Frazzled apart from other review sites? Dive into the unique features that make it the go-to platform for understanding job satisfaction and burnout."</p>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <p className="card-text"><small className="text-muted">September 02, 2024</small></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h3>Compare results by category</h3>
                            </div>
                            <div className="row row-cols-2 row-cols-md-4 g-4 mt-2 mb-5">
                                <div className="col mb-4">
                                    <Link to="/search/JobTitle" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/person.svg" alt="Person" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Job Title</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Salary" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/dollarsign.circle.svg" alt="Salary Bracket" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Salary Bracket</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Age" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/birthday.cake.svg" alt="Age" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Age</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Department" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/building.columns.svg" alt="Department" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Department</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Gender" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="https://img.icons8.com/?size=75&id=1665&format=png&color=000000" alt="Gender" className="img-fluid" />
                                            <h5 className="mt-3 mb-3">Gender</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Seniority" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/gauge.with.needle.svg" alt="Seniority" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Seniority</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/WorkingArrangement" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/house.svg" alt="Working Arrangement" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Working Arrangement</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/SexualOrientation" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/heart.svg" alt="Sexual Orientation" className="img-fluid mt-4" />
                                            <h5 className="mt-3 mb-3">Sexual Orientation</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-md-8 text-center m-5">
                            <h3 className="mt-5" style={{ color: '#BFBFBF' }}>Our mission</h3>
                            <h1 className="mt-4" style={{ fontSize: '2.5em', fontStyle: 'italic' }}>"To improve everyone's happiness at work"</h1>
                            <p className="mt-4 mb-5">
                                We aim to eliminate toxic workplaces by collecting and analyzing employee happiness data.
                                We provide anonymized insights to help companies improve, and highlight those excelling in employee happiness.
                                Our goal is to drive continuous improvement across all industries, so everyone can find more joy and fulfillment at work.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                {/*<h2 className="mt-5 text-center fw-bold">See how your scores compare to others</h2>*/}
                                <div className="col-lg-6 col-md-12 mt-5 mb-lg-5 mb-0">
                                    <div class="bg-white shadow-lg h-100 w-100 d-flex align-items-center justify-content-center p-4 rounded">
                                        <div className="text-center">
                                            <h3 className="w-100">Find out your Health Score</h3>
                                            <img src="/batteries/40.svg" alt="battery-icon" className="my-3" style={{ maxHeight: 175 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-lg-5 mt-3 mb-5">
                                    <div className="bg-white shadow-lg text-center w-100 mb-3 p-4 rounded">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h5 className="text-title">How do you<br />compare to your job title?</h5>
                                            </div>
                                            <img src="/batteries/25.svg" alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                    <div className="bg-white shadow-lg text-center w-100 p-4 rounded">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h5 className="text-title">Do you feel the same as<br />everyone else?</h5>
                                            </div>
                                            <img src="/batteries/62.svg" alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;